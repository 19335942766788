<template>
  <el-dialog :title="!dataForm.id ? '新增' : '修改'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
             label-width="80px">
      <el-form-item label="用户名" prop="username">
        <el-input v-model="dataForm.username" placeholder="用户名" maxLength="10"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password" v-if="!dataForm.id">
        <el-input v-model="dataForm.password" maxLength="13" @input="refresh" type="password"
                  placeholder="密码"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="realName">
        <el-input v-model="dataForm.realName" maxLength="10" placeholder="姓名"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-select v-model="dataForm.gender" placeholder="请选择">
          <el-option label="男" :value="0"></el-option>
          <el-option label="女" :value="1"></el-option>
          <el-option label="保密" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="dataForm.email" placeholder="邮箱"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="dataForm.mobile" placeholder="手机号"></el-input>
      </el-form-item>
      <el-form-item label="角色" size="mini" prop="roleIdList">
        <el-checkbox-group v-model="dataForm.roleIdList">
          <el-checkbox v-for="role in roleList" :key="role.id" :label="role.id">{{ role.name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="状态" size="mini" prop="status">
        <el-radio-group v-model="dataForm.status">
          <el-radio :label="0">禁用</el-radio>
          <el-radio :label="1">正常</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
			<el-button @click="visible = false">取消</el-button>
			<el-button type="primary" @click="dataFormSubmit()">确定</el-button>
		</span>
  </el-dialog>
</template>

<script>
import {
  isEmail,
  isMobile
} from '@/utils/validate'
import sha256 from "sha256";

export default {
  data() {
    var validatePassword = (rule, value, callback) => {
      if (!this.dataForm.id && !/\S/.test(value)) {
        callback(new Error('密码不能为空'))
      } else {
        callback()
      }
    }
    var validateComfirmPassword = (rule, value, callback) => {
      if (!this.dataForm.id && !/\S/.test(value)) {
        callback(new Error('确认密码不能为空'))
      } else if (this.dataForm.password !== value) {
        callback(new Error('确认密码与密码输入不一致'))
      } else {
        callback()
      }
    }
    var validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error('邮箱格式错误'))
      } else {
        callback()
      }
    }
    var validateMobile = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      roleList: [],
      dataForm: {
        id: null,
        username: '',
        realName: '',
        gender: '',
        password: '',
        salt: '',
        email: '',
        mobile: '',
        roleIdList: [],
        status: 1
      },
      dataRule: {
        username: [{
          required: true,
          message: '用户名不能为空',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          validator: validatePassword,
          trigger: 'blur'
        }],
        comfirmPassword: [{
          validator: validateComfirmPassword,
          trigger: 'blur'
        }],
        realName: [{
          required: true,
          message: '姓名不能为空',
          trigger: 'blur'
        }],
        gender: [{
          required: true,
          message: '请选择性别',
          trigger: 'change'
        }],
        email: [{
          required: true,
          message: '邮箱不能为空',
          trigger: 'blur'
        },
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ],
        mobile: [{
          required: true,
          message: '手机号不能为空',
          trigger: 'blur'
        },
          {
            validator: validateMobile,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    init(id) {
      this.visible = true
      this.dataForm.id = id || null;
      this.$get('/platform-admin/role/list').then((data) => {
        this.roleList = data && data.code === 0 ? data.data : [];
      }).then(() => {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
        })
      }).then(() => {
        if (this.dataForm.id) {
          // this.$http({
          // 	url: this.$http.adornUrl(`/sys/user/info/${this.dataForm.id}`),
          // 	method: 'get',
          // 	params: this.$http.adornParams()
          // }).then(({
          // 	data
          // }) => {
          // 	if (data && data.code === 0) {
          // 		this.dataForm.userName = data.user.username
          // 		this.dataForm.salt = data.user.salt
          // 		this.dataForm.email = data.user.email
          // 		this.dataForm.mobile = data.user.mobile
          // 		this.dataForm.roleIdList = data.user.roleIdList
          // 		this.dataForm.status = data.user.status
          // 	}
          // })
          this.$get(`/platform-admin/user/${this.dataForm.id}`).then(re => {
            if (re && re.code === 0) {
              this.dataForm = re.data
            }
          })
        }
      })
    },
    // 刷新
    refresh() {
      this.$forceUpdate()
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate(async (valid) => {
        if (valid) {
          // this.$http({
          //   url: this.$http.adornUrl(`/sys/user/${!this.dataForm.id ? 'save' : 'update'}`),
          //   method: 'post',
          //   data: this.$http.adornData({
          //     'userId': this.dataForm.id || undefined,
          //     'username': this.dataForm.userName,
          //     'password': this.dataForm.password,
          //     'salt': this.dataForm.salt,
          //     'email': this.dataForm.email,
          //     'mobile': this.dataForm.mobile,
          //     'status': this.dataForm.status,
          //     'roleIdList': this.dataForm.roleIdList
          //   })
          // }).then(({data}) => {
          //   if (data && data.code === 0) {
          //     this.$message({
          //       message: '操作成功',
          //       type: 'success',
          //       duration: 1500,
          //       onClose: () => {
          //         this.visible = false
          //         this.$emit('refreshDataList')
          //       }
          //     })
          //   } else {
          //     this.$message.error(data.msg)
          //   }
          // })
          let res
          if (this.dataForm.id) {
            res = await this.$put('/platform-admin/user', this.dataForm);
          } else {
            res = await this.$post('/platform-admin/user', {
              ...this.dataForm,
              password: sha256(this.dataForm.password)
            });
          }
          if (res && res.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.visible = false
                this.$emit('refreshDataList')
              }
            })
          }
        }
      })
    }
  }
}
</script>
